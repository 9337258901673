<template>
  <div class="tw-mb-40">
    <div class="tw-mt-4">
      <div>
        <div class="tw-border-b tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
          <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
            <a @click.prevent="activePage = 'payment_methods'"
               :class="[activePage === 'payment_methods' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']"
               href="#">
              <span>{{ $t('dashboard.menu.payment_methods') }}</span>
            </a>

            <a @click.prevent="activePage = 'stripe'" href="#"
               :class="[activePage === 'stripe' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
              <span>{{ $t('dashboard.menu.stripe') }}</span>
            </a>
          </nav>
        </div>
      </div>
      <v-tabs-items
          v-model="activePage"
          background-color="white"
          color="deep-purple accent-4"
          left
      >
        <v-tab-item value="payment_methods">
          <div>
            <div>
              <spinner v-if="loading"></spinner>
              <div>
                <div class="tw-mt-4 tw-text-sm tw-text-black">Select Payment Type, Due Date, and Percentage Due</div>
                <PaymentMethods />
              </div>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item value="stripe">
          <div>
            <div>
              <spinner v-if="loading"></spinner>
              <div>
                <StripeAccount />
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>


import { mapGetters } from 'vuex';
import StripeAccount from '../payments/stripe/reauthPage.vue';
import PaymentMethods from '../payments/PaymentMethodsPage.vue';
import i18n from '@/i18n'

export default {
  middleware: ['auth', 'vendor', 'is-vendor-active'],
  components: {
    StripeAccount,
    PaymentMethods
  },
  metaInfo(){
    return {
      title: i18n.t('payments'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendor/payments'),
        },
      ]
    }
  },
  data() {
    return {
      query: null,
      vendor: null,
      activePage: 'payment_methods',
      loading: false,
      errored: false,
      error: null,
      items: []
    }
  },

  mounted() {
    //this.loadInitData();
  },
  watch: {},
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  methods: {}
}
</script>
