<template>
  <div>
    <ml-grid>
      <div class="tw-mt-4 tw-text-sm tw-text-black tw-col-span-6">
        Mango & Lola has partnered with <a href="https://stripe.com" target="_blank">Stripe</a> to process your client payments. Stripe is a prominent and secure
        financial
        processing platform used by the largest Fortune 500 companies. In order to accept client payments, the following
        information is required:
      </div>
    </ml-grid>
    <ml-grid>
      <div class="tw-mt-4 tw-text-sm tw-text-black tw-col-span-6">
       <strong>Business Details</strong>
       <p>Business Name; EIN; Address & Website</p>
       
       <strong>Customer Support Details</strong>
       <p>Information that helps customers recognize your business on their statements</p>

       <strong>Management & Ownership</strong>
       <p>Primary contact with authority to represent your company</p>

       <strong>Bank Details</strong>
       <p>Where you would like client payments sent</p>

       <p>
       <strong>Ready to accept payment? </strong>
       <span :style="account.payouts_enabled ? 'color:green':'color:red'" v-if="account">{{account.payouts_enabled ? 'VERIFIED':'NOT VERIFIED'}}</span>
      </p>

       <p>Information provided is for processing transactions and identification purposes only.</p>
       <p>*This is a one-time process. Once verified, you will be able to receive client payments. Click on the link below to get started.</p>

      <spinner v-if="loading"/>

      <div class="tw-mb-8 tw-flex tw-justify-between">
        <button v-if="account" @click="getAccountLink()"
                :disabled="url || loading"
                class="tw-mt-6 tw-flex tw-justify-center tw-leading-relaxed tw-px-8 tw-py-3.5 tw-border tw-border-transparent tw-text-base tw-font-semibold tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
          Update your Stripe account data
        </button>
        <button v-else-if="!account && !loading" @click="createAccount()"
                :disabled="loading"
                class="tw-mt-6 tw-flex tw-justify-center tw-leading-relaxed tw-px-8 tw-py-3.5 tw-border tw-border-transparent tw-text-base tw-font-semibold tw-rounded-xl tw-shadow-sm tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
          Create Stripe account
        </button>

      </div>

      </div>
    </ml-grid>


  </div>
</template>

<script>
//import axios from 'axios'
import i18n from '@/i18n'
import { mapGetters } from 'vuex'
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {MlGrid},

  middleware: ['auth', 'vendor', 'is-vendor-active'],

  metaInfo() {
    return {
      title: i18n.t('stripe-verfication'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendor/stripe/reauth'),
        },
      ]
    }
  },

  /*
    async beforeRouteEnter (to, from, next) {
      try {
        const { data } = await this.$http.get("/api/stripe/AccountLink")
        next(vm => {
          vm.url = data.url })
      } catch (e) {
        next(vm => { vm.error = e.response.data.status })
      }
    },
  */
  data: () => ({
    error: '',
    url: null,
    loading: false,
    account: null
  }),
  mounted() {
    this.getAccount();
    //this.getAccountLink();
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  watch: {
    url: function () {
      if (!this.url) return;
      this.gotoStripe();
    }
  },
  methods: {
    gotoStripe() {
      console.log("Url:", this.url);
      window.open(this.url, '_self');
    },
    getAccountLink() {
      this.loading = true;
      let uri = '/api/stripe/AccountLink';
      this.$http.get(uri)
          .then(({data}) => {
            this.url = data.url
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )
    },
    getAccount() {
      this.loading = true;
      let uri = '/api/stripe/account';
      this.$http.get(uri)
          .then(({data}) => {
            this.account = data.data
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )
    },
    createAccount() {
      this.loading = true;
      let uri = '/api/stripe/account';
      this.$http.post(uri)
          .then(({data}) => {
            this.account = data.data
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )
    }
  }

}
</script>
